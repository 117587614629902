<template>
  <div>
    <b-modal
      :title-html="$t('Editar data da rotina')"
      cancel-variant="outline-secondary"
      size="lg"
      body-class=""
      :visible="true"
      :ok-only="false"
      :ok-title="$t('Confirmar')"
      :cancel-title="$t('Fechar')"
      ok-variant="primary"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      @ok="confirmModal"
      @cancel="closeModal"
    >
      <b-row v-if="data.message">
        <b-col md="12">
          <b-alert
            variant="warning"
            show
            class="mb-2"
          >
            <div class="alert-body">
              {{ data.message }}
            </div>
          </b-alert>

        </b-col>
      </b-row>

      <b-row v-if="showBlockQuestion===true">
        <b-col md="12">
          <b-form-group>
            <h6> {{ $t('O que pretende fazer?') }}</h6>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="showBlockQuestion===true">
        <b-col md="12">
          <b-form-group>
            <b-form-checkbox
              v-model="action"
              value="1"
            >
              {{ $t('Alterar apenas este seguimento e manter a restante rotina tal como está.') }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="(showBlockQuestion===true) && (checkStatus!==1)">
        <b-col md="12">
          <b-form-group>
            <b-form-checkbox
              v-model="action"
              value="2"
            >
              <!-- eslint-disable vue/no-v-html -->
              <span v-html="$t('Alterar este seguimento e todos de {date} para a frente.', { date: '<b>' + getDateNew + '</b>' })" />
              <!--eslint-enable-->
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="(showBlockQuestion===true) && (checkStatus!==2)">
        <b-col md="12">
          <b-form-group>
            <b-form-checkbox
              v-model="action"
              value="3"
            >
              <!-- eslint-disable vue/no-v-html -->
              <span v-html="$t('Alterar todos os seguimentos desta rotina a partir da data de hoje {date}.', { date: '<b>' + getDateNow + '</b>' })" />
              <!--eslint-enable-->
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>

    </b-modal>
  </div>
</template>

<script>
import {
  BModal, BRow, BCol, BAlert, BFormCheckbox, BFormGroup,
} from 'bootstrap-vue'
import { showMsgRequest } from '@core-custom/mixins/geral'

export default {
  components: {
    BModal,
    BAlert,
    BFormCheckbox,
    BFormGroup,
    BRow,
    BCol,
  },
  mixins: [showMsgRequest],
  props: {
    data: {
      type: Object,
      required: true,
    },
    resolve: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      action: null,
    }
  },
  computed: {
    showBlockQuestion() {
      if (
        (this.data !== null) && (this.data !== undefined)
        && ('tipoSeguimento' in this.data) && (Number(this.data.tipoSeguimento) !== 0)
        && ('seguimentoTratado' in this.data) && (Number(this.data.seguimentoTratado) === 0)
      ) {
        return true
      }

      return false
    },
    checkStatus() {
      if ((this.data !== null) && (this.data !== undefined) && ('status' in this.data)) {
        return Number(this.data.status)
      }

      return 0
    },
    getDateNow() {
      if ((this.data !== null) && (this.data !== undefined) && ('dataNow' in this.data)) {
        return this.data.dataNow
      }

      return ''
    },
    getDateNew() {
      if ((this.data !== null) && (this.data !== undefined) && ('newData' in this.data)) {
        return this.data.newData
      }

      return ''
    },
  },
  methods: {
    async confirmModal(evt) {
      evt.preventDefault()

      if (this.action !== null) {
        this.resolve({
          confirmAction: Number(this.action),
          cancel: false,
        })
      } else {
        this.showMsgErrorRequest({ message: this.$t('Escolha uma ação para alterar a data do seguimento') })
      }
    },
    async closeModal() {
      this.resolve({
        confirmAction: false,
        cancel: true,
      })
    },
  },
}
</script>
